<template>
    <b-card no-body>
        <div class="card-header">
            <!-- Title & SubTitle -->
            <div>
                <b-card-title>Kardex</b-card-title>
                <b-card-sub-title></b-card-sub-title>
            </div>

            <!-- Card Actions -->
            <div class="heading-elements">
                <ul class="list-inline mb-0">
                    <li>
                        <b-button variant="flat-secondary" class="btn-icon" @click="refresh">
                            <feather-icon icon="RefreshCwIcon" size="20" />
                        </b-button>
                    </li>
                    <li>
                        <feather-icon icon="HelpCircleIcon" title="" v-b-popover.hover.left="" size="20" />
                    </li>
                </ul>
            </div>
        </div>

        <!-- Card Body  -->
        <b-card-body>
            <ag-grid-vue style="width: 100%; height: 500px" class="ag-theme-alpine" :columnDefs="columnDefs"
                @grid-ready="onGridReady" @first-data-rendered="firstDataRendered" :defaultColDef="defaultColDef"
                :autoGroupColumnDef="autoGroupColumnDef" :groupDefaultExpanded="groupDefaultExpanded"
                @cellValueChanged="onCellValueChanged" :rowData="rowData" :getRowStyle="getRowStyle"></ag-grid-vue>
        </b-card-body>
    </b-card>
</template>
  
<script>


import
{
    BRow,
    BCol,
    BCard,
    BCardBody,
    BCardTitle,
    BCardSubTitle,
    BFormCheckbox,
    BFormGroup,
    BFormCheckboxGroup,
    BAvatar,
    VBPopover,
    BButton
} from "bootstrap-vue";

import { AgGridVue } from "ag-grid-vue";

import axios from '@axios'
var qs = require('qs');
import useApiServices from '@/services/useApiServices.js';

export default {

    directives: {
        "b-popover": VBPopover,
    },

    props: {






    },

    data()
    {


        let planificationId = this.$route.params.planification_id;
        let query = qs.stringify({ planificacionId: planificationId })

        return {

            columnDefs: [

            ],
            gridApi: null,
            columnApi: null,
            
            defaultColDef: {
                editable: (params) =>
                {
                    return params.data.editable;

                },
                flex: 1,
                sortable: false,
                filter: false,
                resizable: true,
                suppressMovable: true,
                valueParser: params =>
                {

                    let newValue = Number(params.newValue)

                    if (isNaN(newValue))
                    {
                        return Number(params.oldValue)
                    }

                    return newValue


                }

            },
            autoGroupColumnDef: null,
            groupDefaultExpanded: null,
            rowData: null,

            dataUrl: useApiServices.detallePlanificacion.planProductivo.tableKardex + "?" + query,
            updateTableKardex: useApiServices.detallePlanificacion.planProductivo.updateTableKardex + "?" + query




        };
    },


    components: {
        BRow,
        BCol,
        BCard,
        BCardBody,
        BFormCheckbox,
        BFormGroup,
        BFormCheckboxGroup,
        BCardTitle,
        BCardSubTitle,
        BAvatar,
        BButton,

        AgGridVue,
        VBPopover

    },

    created()
    {
        this.updateData()
    },


    methods: {

        getRowStyle(params)
        {
            if (params.data.editable)
            {
                return { background: '#93d05b' };
            }
        },

        updateData()
        {
            
            this.getData()
                .then((response) =>
                {
                    this.columnDefs = response.data.columnDefs;
                    this.rowData = response.data.rowData;

                })


        },

        updateResults(params)
        {
            //1 + 2 - 3 = stock final
            //utilizacion = stock final / capacidad

            // 0 => StockInicial
            // 1 => Produccion
            // 2 => Venta
            // 3 => StockFinal
            // 4 => Capacidad
            // 5 => Utilizacion


            let semana_key = "s1"
            this.rowData[3][semana_key] = this.rowData[0][semana_key] + this.rowData[1][semana_key] - this.rowData[2][semana_key]
            this.rowData[5][semana_key] = ((100 * this.rowData[3][semana_key]) / this.rowData[4][semana_key]).toFixed(0) + "%"
            this.rowData[0]["s2"] = this.rowData[3][semana_key]


            semana_key = "s2"
            this.rowData[3][semana_key] = this.rowData[0][semana_key] + this.rowData[1][semana_key] - this.rowData[2][semana_key]
            this.rowData[5][semana_key] = ((100 * this.rowData[3][semana_key]) / this.rowData[4][semana_key]).toFixed(0) + "%"
            this.rowData[0]["s3"] = this.rowData[3][semana_key]

            semana_key = "s3"
            this.rowData[3][semana_key] = this.rowData[0][semana_key] + this.rowData[1][semana_key] - this.rowData[2][semana_key]
            this.rowData[5][semana_key] = ((100 * this.rowData[3][semana_key]) / this.rowData[4][semana_key]).toFixed(0) + "%"
            this.rowData[0]["s4"] = this.rowData[3][semana_key]

            semana_key = "s4"
            this.rowData[3][semana_key] = this.rowData[0][semana_key] + this.rowData[1][semana_key] - this.rowData[2][semana_key]
            this.rowData[5][semana_key] = ((100 * this.rowData[3][semana_key]) / this.rowData[4][semana_key]).toFixed(0) + "%"


            params.api.refreshCells();
            this.saveChanges()


        },

        refresh()
        {

            this.updateData()

        },


        firstDataRendered(params)
        {

            console.log("firstDataRendered")

            //this.gridColumnApi.autoSizeAllColumns();

        },

        onCellValueChanged(params)
        {

            console.log("onCellValueChanged");
            console.log(params);

            console.log(this.rowData);

            this.updateResults(params)

            this.$emit('change', this.gridApi, this.gridColumnApi, params);
        },

        onGridReady(params)
        {
            this.gridApi = params.api;
            this.gridColumnApi = params.columnApi;

            console.log("onGridReady")

            //const updateData = (data) => params.api.setRowData(data.slice(0, 50));

        },


        getData()
        {
            return axios.request({
                method: "get",
                url: this.dataUrl,
                headers: {
                    Accept: "application/json",
                },
            })
        },

        saveChanges()
        {
            return axios.post(this.updateTableKardex, {
                "kardex_data" : {
                    "columnDefs" : this.columnDefs,
                    "rowData" :this.rowData 
                }
            })
            
        }



    },
};
</script>
  
<style scoped>
.titulo {
    margin-top: 5%;
}
</style>
  
  
<style lang="scss" scoped>
@import "~ag-grid-community/dist/styles/ag-grid.css";
@import "@core/scss/vue/pages/ui-feather.scss";

//@import "~ag-grid-community/dist/styles/ag-theme-alpine.css";
@import "~ag-grid-community/dist/styles/ag-theme-alpine.css";

div.ag-watermark {
    display: none !important;
}

.ag-pivot-off .ag-header-group-cell {
    font-size: 25px;
    color: #8a6d3b;
}

.ag-pivot-on .ag-header-group-cell {
    font-size: 25px;
    color: #8a6d3b;
}

.ag-cell-label-container {
    /* Necessary to allow for text to grow vertically */
    height: 100%;
}

.ag-header-cell-label {
    /* Necessary to allow for text to grow vertically */
    height: 100%;
    padding: 0 !important;
}

.ag-header-cell-label .ag-header-cell-text {
    /* Force the width corresponding at how much width
      we need once the text is laid out vertically */
    //width: 30px;
    //transform: rotate(90deg);
    //margin-top: 50px;
    /* Since we are rotating a span */
    display: inline-block;
}

.ag-floating-filter-body input {
    height: 49px;
}

.ag-floating-filter-button {
    margin-top: -49px;
}

.ag-floating-filter-button button {
    height: 49px;
}

.ag-floating-filter-body input {
    font-size: 15px;
    font-weight: bold;
}

.ag-pivot-off .ag-header-cell-label {
    color: #8a6d3b;
}

.ag-pivot-on .ag-header-cell-label {
    color: #1b6d85;
    font-weight: bold;
}
</style>
  
<style lang="scss" scoped>
.card {
    .card-title {
        margin-bottom: 1.5rem !important;
    }

    ::v-deep .card-header {
        .heading-elements {
            position: static;
            cursor: inherit;

            .list-inline {
                display: block;

                li {
                    a {
                        padding: 0;
                    }

                    &:not(:last-child) {
                        margin-right: 1rem;
                    }
                }
            }
        }
    }
}
</style>
  
  