<template>
  <div>
    <b-overlay :show="showOverlay" rounded="sm">

      <b-row>
      <b-col>
        <b-card no-body>
          <div class="card-header">
            <!-- Title & SubTitle -->
            <div>
              <b-card-title>{{ $t("Detalle de Planificación") }}</b-card-title>
              <b-card-sub-title></b-card-sub-title>
            </div>

            <!-- Card Actions -->
            <div class="heading-elements">
              <ul class="list-inline mb-0">
                <li></li>
                <li></li>
              </ul>
            </div>
          </div>

          <!-- Card Body  -->
          <b-card-body>
            <b-row>
              <b-col>
                <b-list-group>
                  <b-list-group-item
                    ><b>{{ $t("Id") }}:</b>
                    {{ this.invocation_id }}</b-list-group-item
                  >
                  <b-list-group-item
                    ><b>{{ $t("Name") }}:</b>
                    {{ this.invocation_name }}</b-list-group-item
                  >
                  <b-list-group-item
                    ><b>{{ $t("Created At") }}:</b>
                    {{ this.invocation_created_at }}</b-list-group-item
                  >

                  <b-list-group-item
                    ><b>{{ $t("Tipo Function Objetivo") }}:</b>
                    {{
                      this.invocation_tipo_funcion_objetivo
                    }}</b-list-group-item
                  >

                  <b-list-group-item
                    ><b>{{ $t("Tipo Ajuste") }}:</b>
                    {{ this.invocation_tipo_ajuste }}</b-list-group-item
                  >

                  <b-list-group-item
                    ><b>{{ $t("Status") }}:</b>
                    {{ this.invocation_status }}</b-list-group-item
                  >

                  <b-list-group-item
                    ><b>{{ $t("Generación Actual") }}:</b>
                    {{ this.invocation_current_generation }}</b-list-group-item
                  >

                  <b-list-group-item
                    ><b>{{ $t("Tiempo Restante") }}:</b>
                    {{
                      this.invocation_tiempo_restante_human
                    }}</b-list-group-item
                  >
                </b-list-group>
              </b-col>

              <b-col>
                <b-form-tags
                  v-model="tag_names"
                  :placeholder="$t('Add tags...')"
                  @input="tagInput"
                ></b-form-tags>
              </b-col>

              <!--

              <b-button @click="testButton">Button</b-button>
-->

              <!--
              <b-col>
                <b-form-checkbox
                  v-model="liveReload"
                  name="check-button"
                  @change="liveReloadChange"
                  button
                  button-variant="outline-info"
                >
                  <feather-icon
                    icon="RefreshCwIcon"
                    :badge="updatesCount"
                    badge-classes="badge-info badge-glow"
                    size="21"
                  />
                </b-form-checkbox>
              </b-col>
              -->
            </b-row>
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>

      <b-tabs content-class="mt-3">
        <b-tab title="Dashboard">
          <PlanProductivo :planificationId="planificationId" ></PlanProductivo>
        </b-tab>
        <b-tab title="Planificacion Productiva">
          <PlanProductivo2 :planificationId="planificationId" ></PlanProductivo2>
        </b-tab>
        <b-tab title="Funcion Objetivo">
          <DetalleFuncionObjetivo :planificationId="planificationId" ></DetalleFuncionObjetivo>
        </b-tab>
      </b-tabs>

      <template #overlay>
        <div class="text-center">
          <b-spinner label="Loading..." />
          <p id="cancel-label">Cargando Resultados...</p>
        </div>
      </template>
    </b-overlay>
  </div>
</template>

<script>




import
{
  BTabs,
  BTab,
  BOverlay,
  BSpinner
} from "bootstrap-vue";

import PlanCosecha from "./plan-cosecha/PlanCosecha.vue";
import PlanProductivo from "./plan-productivo/PlanProductivo.vue";
import PlanProductivo2 from "./plan-productivo/PlanProductivo2.vue";
import Comercial from "./comercial/Comercial.vue";
import DetalleJaula from "./detalle-jaula/DetalleJaula.vue"
import DetalleFuncionObjetivo from './detalle-funcion-objetivo/DetalleFuncionObjetivo.vue';

import axios from '@axios'

var qs = require('qs');
import useApiServices from '@/services/useApiServices.js';

export default {
  data()
  {
    return {
      showOverlay: false,
      planificationId: null,

      invocation_id: "",
      invocation_name: "",
      invocation_created_at: "",
      invocation_status: "",
      invocation_tipo_funcion_objetivo: "",
      invocation_tipo_ajuste: "",
      invocation_current_generation: '',
      invocation_tiempo_restante_human: '',



    };
  },
  created()
  {

    this.planificationId = this.$route.params.planification_id;



  },

  mounted() {

    let optimization_id = this.$route.params.planification_id;

    this.getInvocationDetails(optimization_id)



  },

  methods: {

    tagInput(value)
    {
      let data = {
        tags: value
      }

      let optimization_id = this.$route.params.planification_id;

      useApiServices.postInvocationAddTags(optimization_id, data)
        .then((response) =>
        {

        })

    },

    
    getInvocationDetails(invocation_id)
    {
      useApiServices.getInvocationDetails(invocation_id)
        .then((response) =>
        {
          let invocation_details = response.data

          this.invocation_id = invocation_details.id
          this.invocation_name = invocation_details.name
          this.invocation_created_at = localDateFormatter(invocation_details.created_at)
          this.invocation_status = invocation_details.status

          this.invocation_tipo_funcion_objetivo = invocation_details.tipo_funcion_objetivo
          this.invocation_tipo_ajuste = invocation_details.tipo_de_solucion

          this.generation = invocation_details.generation
          this.max_generation = invocation_details.max_generation

          this.invocation_current_generation = this.generation + " de " + this.max_generation

          let invocation_tiempo_restante = invocation_details.invocation_tiempo_restante
          var duration = moment.duration(invocation_tiempo_restante * 1000);

          this.invocation_tiempo_restante_human = duration.humanize()

        })

    },


  },

  components: {
    BTabs,
    BTab,
    BOverlay,
    BSpinner,



    PlanCosecha,
    PlanProductivo,
    Comercial,
    DetalleJaula,
    PlanProductivo2,
    DetalleFuncionObjetivo
  },



};
</script>

<style scoped>
.btn-padding {
  margin-right: 48px;
}

.m-estadisticas {
  margin-left: 20px;
  margin-bottom: 1%;
  margin-top: 1%;
}
.icon-plan {
  padding-top: 5%;
}
</style>
